import { ERROR_TEXTS } from 'lib/constants';
import { getDFU } from 'lib/helpers';
import Package from 'lib/helpers/package';
import { useFilePackageWrap, useFirmwareDownload } from 'lib/hooks';
import { useState } from 'react';
import SecureDfu from 'web-bluetooth-dfu';

import { PlonqBootLoader } from '../plonq-boot-loader';

/**
 * @interface
 * @property object - тип загрузки данных. Нужно для корректной работы UI прогресс бара.
 * @property currentBytes - переданное количество байтов.
 * @property totalBytes - байтов надо передать.
 */
export interface UpdateProgressHandlerParams {
  object: 'init' | 'firmware';
  currentBytes: number;
  totalBytes: number;
}

/**
 * Используется для искусственной задержки во время обновления.
 */
const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

/**
 * @interface
 */
export interface PreparedUpdateFirmwareState {
  dfu: SecureDfu;
  packagedFirmwareFile: Package;
  device?: BluetoothDevice;
}

/**
 * Хук инкапсулирует логику обновления прошивки.
 * Отвечает за скачивание файла с прошивкой и оборачивание его в dfu пакеты,
 * переключение устройства в режим bootloader и подключение к нему под новым именем,
 * инициализацию отправки пакетов с прошивками и отслеживание прогресса передачи пакетов.
 *
 * Использует экземпляр {@link PlonqBootLoader} для перевода устройства в режим bootloader.
 *
 * @example
 * const { prepareFirmwareFile, updateDevice, isFilePrepared } = useDeviceUpdate();
 *
 * const lastFirmwareFileKey = await getLatestFirmwareFileKey();
 * await prepareFirmwareFile(lastFirmwareFileKey);
 *
 * if (isFilePrepared) {
 *  await updateDevice();
 * }
 *
 * @returns объект с функциями для управления процессом обновления устройства.
 */
const useDeviceUpdate = () => {
  const downloadFirmware = useFirmwareDownload();
  const wrapFile = useFilePackageWrap();

  const [updateProgress, setUpdateProgress] = useState<number>(0);
  const [preparedState, setPreparedState] =
    useState<PreparedUpdateFirmwareState>();

  const setDeviceInBootLoaderMode = async () => {
    return PlonqBootLoader.getInstance().setBootLoaderMode();
  };

  const handleUpdateProgressUpdate = (params: UpdateProgressHandlerParams) => {
    if (params.object === 'firmware') {
      setUpdateProgress(() =>
        Math.trunc((params.currentBytes / params.totalBytes) * 100),
      );
    }
  };

  const prepareUpdateFile = async (firmwareFile: File) => {
    return wrapFile(firmwareFile);
  };

  const connectDeviceForUpdate = async (dfu: SecureDfu) => {
    try {
      const device = await dfu.requestDevice(false, [
        { name: 'DfuTarg' },
        { namePrefix: 'DfuTarg' },
      ]);

      return device;
    } catch (error) {
      throw new Error(ERROR_TEXTS.cantConnectToDevice);
    }
  };

  const prepareFirmwareFile = async (firmwareBlob: File) => {
    const packagedFirmwareFile = await prepareUpdateFile(firmwareBlob);
    const dfu = getDFU();

    await setDeviceInBootLoaderMode();
    await wait(200);

    setPreparedState(() => ({
      dfu,
      packagedFirmwareFile,
    }));
  };

  const connectDevice = async () => {
    if (!preparedState) {
      throw new Error(ERROR_TEXTS.noFirmwareFile);
    }

    const device = await connectDeviceForUpdate(preparedState.dfu);

    setPreparedState(() => ({ ...preparedState, device }));
    updateDevice(device);
  };

  const updateDevice = async (device: BluetoothDevice) => {
    if (!preparedState) {
      throw new Error(ERROR_TEXTS.noFirmwareFile);
    }

    const { dfu, packagedFirmwareFile } = preparedState;

    dfu.addEventListener('progress', handleUpdateProgressUpdate);

    const baseImage: any = await packagedFirmwareFile.getBaseImage();
    baseImage &&
      (await dfu.update(device, baseImage.initData, baseImage.imageData));

    const appImage: any = await packagedFirmwareFile.getAppImage();
    appImage &&
      (await dfu.update(device, appImage.initData, appImage.imageData));

    dfu.removeEventListener('progress', handleUpdateProgressUpdate);
  };

  return {
    prepareFirmwareFile,
    updateDevice: connectDevice,
    updateProgress,
    isFilePrepared: !!preparedState,
  };
};

export default useDeviceUpdate;
