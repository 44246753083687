import { UserFirmwareDownloadFileKey } from 'api';
import { API_URL } from 'lib/constants';
import { HttpMethods } from 'types';

import { baseApi } from '../baseApi';

export const firmwareApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getFirstAvailableFirmware: builder.query<
      UserFirmwareDownloadFileKey,
      { deviceId: string }
    >({
      query: ({ deviceId }) => ({
        url: API_URL.getFirstAvailableFirmware(deviceId),
        method: HttpMethods.get,
      }),
      providesTags: ['firmwares'],
      keepUnusedDataFor: 0,
    }),
    downloadFirstAvailableFirmware: builder.query<File, { deviceId: string }>({
      query: ({ deviceId }) => ({
        url: API_URL.downloadLatestAvailableFirmware(deviceId),
        method: HttpMethods.get,
        responseHandler: async (response) => {
          if (!response.ok) {
            throw new Error('Не удалось скачать файл');
          }

          const blob = await response.blob();
          return new File([blob], 'firmware.zip', {
            type: 'application/zip',
          });
        },
      }),
      providesTags: ['firmwares'],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetFirstAvailableFirmwareQuery,
  useDownloadFirstAvailableFirmwareQuery,
} = firmwareApi;
