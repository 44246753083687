import { ERROR_TEXTS } from 'lib/constants';
import useDeviceState from 'lib/services/plonq/hooks/useDeviceState';
import useDeviceUpdate from 'lib/services/plonq/hooks/useDeviceUpdate';
import { PlonqDevice } from 'lib/services/plonq/plonq-device';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDownloadFirstAvailableFirmwareQuery } from 'store/api/firmware';
import styled from 'styled-components';
import { Spinner } from 'ui';

import { CompleteState } from './states/CompleteState';
import { FailedState } from './states/FailedState';
import { PrepareState } from './states/PrepareState';
import { ProcessState } from './states/ProcessState';

export interface IUpdateDeviceState {
  changeState(state: UpdateDeviceStates): void;
}

export enum UpdateDeviceStates {
  PREPARE = 'prepare',
  PROCESS = 'process',
  COMPLETE = 'complete',
  FAILED = 'failed',
}

/**
 * Страница обновления прошивки.
 * Доступно только для авторизированных пользователей.
 *
 * Требует deviceId в url-параметрах.
 * Требует подключенного {@link PlonqDevice}.
 *
 * Запрашивает наиболее новую доступную версию прошивки.
 * Из полученной прошивки берется fileKey и скачивается файл прошивки
 * из хранилища. Для подготовки файла для установки используется пакет dfu.
 * После установки обновления пользователь переходит на экран списка устройств, тк
 * подключение к устройству теряется в процессе.
 *
 * Для страницы представлено несколько состояний:
 * {@link InfoState}, {@link PrepareState}, {@link ProcessState}, {@link CompleteState}, {@link FailedState}
 *
 * Переход между состояниями:
 * InfoState -> PrepareState -> ProcessState -> CompleteState;
 * Из любого состояния возможен переход в FailedState.
 * Если переход в FailedState был по причине потери связи, пользователь
 * перенаправляется на экран списка устройств. В остальных случах компонент
 * возвращается в InfoState и процесс установки можно пройти заново.
 *
 * Для установки обновления устройство обязано быть поставлено на зарядку.
 */
export const UpdateDevice = () => {
  const { deviceId } = useParams();

  const [deviceState, setDeviceState] = useDeviceState();
  const [errorText, setErrorText] = useState<string>();

  const { updateDevice, prepareFirmwareFile, updateProgress, isFilePrepared } =
    useDeviceUpdate();

  const [state, setState] = useState<UpdateDeviceStates>(
    UpdateDeviceStates.PREPARE,
  );

  const { data: firmwareFile, isLoading: isFirmwareFileLoading } =
    useDownloadFirstAvailableFirmwareQuery(
      { deviceId: deviceId as string },
      { skip: !deviceId },
    );

  useEffect(() => {
    if (!deviceState) {
      const deviceInstance = PlonqDevice.getInstance();
      setDeviceState(deviceInstance);

      if (!deviceInstance?.checkIsBleConnected() && !isFilePrepared) {
        setErrorText(() => ERROR_TEXTS.cantConnectToDevice);
        setState(() => UpdateDeviceStates.FAILED);
      }
    }
  }, [deviceState, setDeviceState, isFilePrepared]);

  const changeState = useCallback((state: UpdateDeviceStates) => {
    setState(() => state);
  }, []);

  if (
    ((!deviceState && !isFilePrepared) || !deviceId || isFirmwareFileLoading) &&
    state !== UpdateDeviceStates.FAILED
  ) {
    return (
      <SpinnerWrapper>
        <Spinner />
        <div>
          {!deviceId
            ? 'Инициализация устройства...'
            : isFirmwareFileLoading
            ? 'Проверяем файл прошивки...'
            : 'Соединение с устройством...'}
        </div>
      </SpinnerWrapper>
    );
  }

  const handlePrepareFirmwareFile = () => {
    if (!firmwareFile) {
      setErrorText(ERROR_TEXTS.cantGetFirmware);
      return changeState(UpdateDeviceStates.FAILED);
    }

    return prepareFirmwareFile(firmwareFile);
  };

  switch (state) {
    case UpdateDeviceStates.PREPARE: {
      return (
        <PrepareState
          isCharging={!!deviceState?.isCharging}
          changeState={changeState}
          prepareUpdate={handlePrepareFirmwareFile}
          updateDevice={updateDevice}
          isFirmwareFilePrepared={isFilePrepared}
          setErrorText={setErrorText}
        />
      );
    }
    case UpdateDeviceStates.PROCESS: {
      return (
        <ProcessState progress={updateProgress} changeState={changeState} />
      );
    }
    case UpdateDeviceStates.COMPLETE: {
      return <CompleteState />;
    }
    case UpdateDeviceStates.FAILED: {
      return (
        <FailedState
          state={state}
          errorText={errorText}
          changeState={changeState}
          hasDeviceConnect={!!deviceState}
        />
      );
    }
  }
};

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: var(--100vh-height);
`;
